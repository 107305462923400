import { computed, inject, Injectable, signal } from '@angular/core';
import { map, Observable, tap } from 'rxjs';

import { ApiService } from '@features/api';
import { Ticket } from '@features/events';
import {
  AddToBasketRequest,
  AddToBasketRequestTicket,
  Basket,
  PaymentIntent,
  ValidateCheckoutResponse,
} from '../models';

@Injectable({ providedIn: 'root' })
export class BasketService {
  #api = inject(ApiService);

  #basket = signal<Basket | null>(null);
  basket = this.#basket.asReadonly();

  inProgress = computed(() => {
    const basket = this.#basket();

    if (!basket) {
      return false;
    }

    const expires = new Date(basket.ticket_hold_end_time);

    return !basket.checked_out && expires > new Date();
  });

  clear() {
    this.#basket.set(null);
  }

  addTickets(tickets: Ticket[]) {
    const payload = this.getAddToBasketPayload(tickets);
    return this.#api.post<Basket>('/basket', payload).pipe(
      map((basket) => this.mapBasket(basket)),
      tap((basket) => this.#basket.set(basket)),
    );
  }

  getBasket(): Observable<Basket> {
    return this.#api.get<Basket>('/basket').pipe(
      map((basket) => this.mapBasket(basket) as Basket),
      tap((basket) => this.#basket.set(basket)),
    );
  }

  validateCheckout() {
    return this.#api.get<ValidateCheckoutResponse>('/basket/checkout/validate');
  }

  getPaymentIntent() {
    return this.#api.get<PaymentIntent>('/basket/checkout');
  }

  private getAddToBasketPayload(tickets: Ticket[]): AddToBasketRequest {
    const payload = tickets.reduce((acc: AddToBasketRequestTicket[], ticket): AddToBasketRequestTicket[] => {
      const options = new Map();
      for (const option of ticket.options) {
        const counter = options.get(option()) ?? 0;
        options.set(option(), counter + 1);
      }

      const mappedTickets: AddToBasketRequestTicket[] = [];
      for (const [ticket_option, quantity] of options.entries()) {
        mappedTickets.push({
          ticket_type_id: ticket.id,
          quantity,
          ticket_option,
        });
      }

      return [...acc, ...mappedTickets];
    }, []);

    return { tickets: payload };
  }

  private mapBasket(basket: Basket): Basket | null {
    if (basket.tickets.length === 0) {
      return null;
    }

    return {
      ...basket,
      ticket_hold_start_time: new Date(basket.ticket_hold_start_time),
      ticket_hold_end_time: new Date(basket.ticket_hold_end_time),
    };
  }
}
